<template>
	<div>
		<el-form :inline="true">
			<el-form-item>
				<el-input
						v-model="searchForm.username"
						placeholder="用户名"
						clearable
				>
				</el-input>
			</el-form-item>

			<el-form-item>
				<el-button @click="getUserList">搜索</el-button>
			</el-form-item>

			<el-form-item>
				<el-button type="primary" @click="handleAddForm()" v-if="hasAuth('sys:member')">新增</el-button>
			</el-form-item>
			<el-form-item>
				<el-popconfirm title="这是确定批量删除吗？" @confirm="delHandle(null)">
					<el-button type="danger" slot="reference" :disabled="delBtlStatu" v-if="hasAuth('sys:member')">批量删除</el-button>
				</el-popconfirm>
			</el-form-item>
		</el-form>

		<el-table
				ref="multipleTable"
				:data="tableData"
				tooltip-effect="dark"
				style="width: 100%"
				border
				stripe
				@selection-change="handleSelectionChange">

			<el-table-column
					type="selection"
					width="55">
			</el-table-column>

			<el-table-column
					prop="username"
					label="用户名"
					width="220">
			</el-table-column>

			<el-table-column
					prop="phoneNumber"
					label="手机号"
					width="150">
			</el-table-column>

			<el-table-column
					prop="statu"
					label="状态"
					width="70"
					>
				<template slot-scope="scope">
					<el-tag size="small" v-if="scope.row.statu === 1" type="success">正常</el-tag>
					<el-tag size="small" v-else-if="scope.row.statu === 0" type="danger">停用</el-tag>
				</template>

			</el-table-column>
			
			<el-table-column
					prop="createdTime"
					width="100"
					label="创建日期"
					:formatter="dateFormat">
			</el-table-column>
			<el-table-column
					prop="rd"
					width="200"
					label="剩余时长(分)">
			</el-table-column>
			<el-table-column
					prop="lastLoginCity"
					width="200"
					label="最后登录地点">	
			</el-table-column>
			<el-table-column
					prop="lastLoginIp"
					width="200"
					label="最后登录ip">	
			</el-table-column>
			<el-table-column
					prop="lastLoginTime"
					width="200"
					label="最后登录时间"
					:formatter="dateFormatMinutes">	
			</el-table-column>
			<el-table-column
					prop="icon"
					
					label="操作">

				<template slot-scope="scope">
					<el-button type="text" @click="repassHandle(scope.row.id)">重设密码</el-button>
					<el-divider direction="vertical"></el-divider>

					<el-button type="text" @click="editHandle(scope.row)">编辑</el-button>
					<el-divider direction="vertical"></el-divider>
					<template>
						<el-popconfirm title="确认停用此用户吗" @confirm="deactive(scope.row.id)">
							<el-button type="text" slot="reference">停用</el-button>
						</el-popconfirm>
					</template>
					<el-divider direction="vertical"></el-divider>
					<template>
						<el-popconfirm title="确认删除此用户吗" @confirm="delHandle(scope.row.id)">
							<el-button type="text" slot="reference">删除</el-button>
						</el-popconfirm>
					</template>
				</template>
			</el-table-column>

		</el-table>

		<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				layout="total, sizes, prev, pager, next, jumper"
				:page-sizes="[10, 20, 50, 100]"
				:current-page="current"
				:page-size="size"
				:total="total">
		</el-pagination>


		<!--新增/编辑对话框-->
		<el-dialog
				title="提示"
				:visible.sync="dialogVisible"
				width="600px"
				:before-close="handleClose">
			<el-form :model="editForm" :rules="editFormRules" ref="editForm">
				<el-form-item label="用户名" prop="username" label-width="150px" :key="usernameInputKey">
					<el-input v-model="editForm.username" autocomplete="off" :disabled="username_disabled"></el-input>
					<el-alert
							title="初始密码为888888"
							:closable="false"
							type="info"
							style="line-height: 12px;"
					></el-alert>
				</el-form-item>

				<el-form-item label="手机号"  prop="phoneNumber" label-width="150px">
					<el-input v-model="editForm.phoneNumber" autocomplete="off"></el-input>
				</el-form-item>

				<el-form-item label="状态"  prop="statu" label-width="150px">
					<el-radio-group v-model="editForm.statu">
						<el-radio  :label="0">停用</el-radio>
						<el-radio  :label="1">正常</el-radio>
					</el-radio-group>
				</el-form-item>

				<el-form-item label="总时长（分）"  prop="rd" label-width="150px">
					<!-- <el-input v-model="editForm.rd" autocomplete="off"></el-input> -->
					<el-input-number v-model="editForm.rd" :max="editForm.max_rd" :min="0"></el-input-number>
				</el-form-item>
				
			</el-form>
			<div slot="footer" class="dialog-footer" >
				<el-button @click="resetForm('editForm')">取 消</el-button>
				<el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
			</div>
		</el-dialog>

		<!-- 重设会员密码对话框 -->
		<el-dialog
				title="重设密码"
				:visible.sync="resetMemberPassVisible"
				width="450px"
				:before-close="handleCloseResetMember">
			<el-form :model="resetMemberPassForm" :rules="resetMemberPassFormRules" ref="resetMemberPassForm">
				

				<el-form-item label="密码"  prop="password" label-width="90px">
					<el-input v-model="resetMemberPassForm.password"  type="password" autocomplete="off" style="width: 300px"></el-input>
				</el-form-item>
				<el-form-item label="确认密码"  prop="confirmPassword" label-width="90px">
					<el-input v-model="resetMemberPassForm.confirmPassword" type="password" autocomplete="off" style="width: 300px"></el-input>
				</el-form-item>
				
				
			</el-form>
			<div slot="footer" class="dialog-footer" >
				<el-button @click="resetForm('resetMemberPassForm')">取 消</el-button>
				<el-button type="primary" @click="resetMemPass">确 定</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
import { provinceAndCityData, CodeToText } from 'element-china-area-data'
	export default {
		name: "Member",
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.resetMemberPassForm.password) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			return {
				usernameInputKey:0,//让“用户名”输入框的css样式重新进行渲染设置的一个flg
				resetMemberPassVisible: false,//重设会员密码对话框

				username_disabled: false,//用户名输入框的活性控制flg
				// city_options : cities.city_options,
				city_options: provinceAndCityData,
				resetMemberPassForm:{//重设会员密码表单

				},
				agentLevelOptions: 
				[	{
						value: 1,
						label: '一级代理商'
					}, 
					{
						value: 2,
						label: '二级代理商'
				}],
				
				searchForm: {},
				delBtlStatu: true,

				total: 0,
				size: 10,
				current: 1,

				dialogVisible: false,
				setDurationDialogVisible:false,

				//“编辑”对应对话框formdata
				editForm: {

				},
				durationForm:{
					
				},

				tableData: [],

				//todo
				editFormRules: {
					allUsername:{},
					username: [
						{required: true, message: '请输入用户名称', trigger: 'blur'},
						{ validator: this.checkUsername, trigger: 'blur'},
						{
							min: 4,
							max: 20,
							message: "长度在 4 到 20 个字符",
							trigger: "blur",
						},
					],
					phoneNumber:[
						{pattern: /^1[0-9]{10}$/, message: '请输入正确的手机号', trigger: 'blur'}
					],
					rd: [
						{required: true, message: '请输入总时长', trigger: 'blur'},
						{pattern: /^[0-9]*$/, message: '请输入正整数', trigger: 'blur'}
					],
					statu: [
						{required: true, message: '请选择状态', trigger: 'blur'},
					]
				},
				durationFormRules:{
					rd: [
						{required: true, message: '请输入时长', trigger: 'blur'},
						{pattern: /^[0-9]*$/, message: '请输入正整数', trigger: 'blur'}
					]
				},
				resetMemberPassFormRules:{
					password: [
						{ required: true, message: '请输入新密码', trigger: 'blur' },
						{ min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur' }
					],
					confirmPassword: [
						{ required: true, validator: validatePass, trigger: 'blur' }
					],
				},
				
				multipleSelection: [],

				roleDialogFormVisible: false,
				defaultProps: {
					children: 'children',
					label: 'name'
				},
				roleForm: {},
				roleTreeData:  [],
				treeCheckedKeys: [],
				checkStrictly: true

			}
		},
		created() {
			this.getUserList();
			
			// this.$axios.get("/sys/role/list").then(res => {
			// 	this.roleTreeData = res.data.data.records
			// })
		},
		// watch(){

			
		// },
		methods: {
			resetMemPass(){
				this.$refs['resetMemberPassForm'].validate((valid) => {
					if (valid) {
						//重设会员密码
						this.$axios.post("/member/updatePass", this.resetMemberPassForm).then(res => {

							//关闭对话框
							this.resetMemberPassVisible = false;
							//清空重设密码对话框data
							this.$refs['resetMemberPassForm'].resetFields();
							this.$message({
								showClose: true,
								message: '操作成功',
								type: 'success',
								onClose:() => {
									this.getUserList()
								}
							});
						})
					}
				})
			},

			//停用代理商
			deactive(id){
				this.$axios.get("/member/deactive?id="+id).then(res => {
					this.$message({
						showClose: true,
						message: '停用成功',
						type: 'success',
						onClose:() => {
							this.getUserList()
						}
					});
				})
			},	

			//检测“可开二级代理商数量“是否为活性，totalSubNum能否为活性 ture:非活性，false:活性
			subTotalAgentNumActiveCheck(){


				//1.活性条件1--当前执行操作的用户为一级代理商以上权限
				let check1 = this.hasAuth('ROLE_admin');
				//1.活性条件2--当前选中的“代理等级不为‘二级代理商’”）
				let check2 = (this.editForm.agentLevel != 2);
				//顺便处理当代理等级选中为“二级代理商”时，清空“可开二级代理商数量”
				if(!check2)
					this.editForm.totalSubNum = 0;
				return !(check1&&check2);
			},
			//检测用户名是否已存在
			checkUsername(rule,value,callback){
				//axios请求后台 对输入用户名进行检测
				this.$axios.get("/member/username/check?username="+value).then(res => {//不存在：true；已存在：false
					if(res.data.data)
						callback();
					else
						callback(new Error('用户名已存在'));
				})
				
			},

			//城市非空check；因为城市用city_options存放，check用prop：city进行check，给city随便赋一个tmp值
			cityChange(){
				this.editForm.city = 'tmp';
			},
			//点击“新增”按钮时初始化数据
			handleAddForm(){
				
				//如果没有username——check，加上
				if(this.editFormRules.username == undefined){
					this.editFormRules.username = [
						{required: true, message: '请输入用户名称', trigger: 'blur'},
						{ validator: this.checkUsername, trigger: 'blur'},
						{
							min: 4,
							max: 20,
							message: "长度在 4 到 20 个字符",
							trigger: "blur",
						}
					];
				}
				
				//获取操作者信息，提供max信息给el组件的输入框
				this.$axios.get('/sys/user/get/operator').then(res => {
					this.editForm.max_rd = res.data.data.rd
					this.username_disabled = false;
					this.usernameInputKey ++;
					this.dialogVisible = true;
					
					if(this.$refs['editForm'] != undefined)this.$refs['editForm'].clearValidate();//操作了validate的rule后，不清除validate状态会出现问题

				})	
			},
			dateFormat(row,column){
				var t=new Date(row.createdTime);//row 表示一行数据, updateTime 表示要格式化的字段名称
        		return t.getFullYear()+"-"+(t.getMonth()+1)+"-"+t.getDate()
				//return t.getFullYear()+"-"+(t.getMonth()+1)+"-"+t.getDate()+" "+t.getHours()+":"+t.getMinutes();
			},
			dateFormatMinutes(row,column){
				if(row.lastLoginTime ==null||row.lastLoginTime=="")
					return ""
				var t=new Date(row.lastLoginTime);//row 表示一行数据, updateTime 表示要格式化的字段名称
				let minutes = t.getMinutes();
				if(minutes<10)
					minutes = "0"+ minutes;
        		return t.getFullYear()+"-"+(t.getMonth()+1)+"-"+t.getDate()+" "+t.getHours()+":"+minutes;
				//return t.getFullYear()+"-"+(t.getMonth()+1)+"-"+t.getDate()+" "+t.getHours()+":"+t.getMinutes();
			},
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				console.log("勾选")
				console.log(val)
				this.multipleSelection = val;

				this.delBtlStatu = val.length == 0
			},

			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.size = val
				this.getUserList()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.current = val
				this.getUserList()
			},

			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.dialogVisible = false
				
				this.resetMemberPassVisible = false
				this.editForm = {}
			},
			resetDurationForm(formName){
				this.$refs[formName].resetFields();
				this.setDurationDialogVisible = false;
				this.durationForm = {}
			},
			handleClose() {
				this.resetForm('editForm')
			},
			handleCloseResetMember() {
				this.$refs['resetMemberPassForm'].resetFields()
				this.resetMemberPassVisible = false
			},
			handleDurationClose(){
				this.resetDurationForm('durationForm')
			},

			getUserList() {
				this.$axios.get("/member/list", {
					params: {
						username: this.searchForm.username,
						current: this.current,
						size: this.size
					}
				}).then(res => {
					this.tableData = res.data.data.records
					this.size = res.data.data.size
					this.current = res.data.data.current
					this.total = res.data.data.total
				})
                
			},

			submitForm(formName) {//todo
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$axios.post('/member/' + (this.editForm.id?'update' : 'save'), this.editForm)
							.then(res => {
								this.$message({
									showClose: true,
									message: '操作成功',
									type: 'success',
									onClose:() => {
										this.getUserList()
									}
								});
								this.resetForm(formName)//清理掉对话框中的信息，避免再次点击“新增”按钮会有上次的信息
								
							})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//新增一级代理商 or 编辑一级代理商 or 设置一级代理商时长
			submitAgentForm(){
				this.$axios.post('/sys/agent/' + (this.durationForm.id?'update' : 'save'), this.durationForm)
							.then(res => {

								this.$message({
									showClose: true,
									message: '操作成功',
									type: 'success',
									onClose:() => {
										this.getUserList()
									}
								});
								
								this.setDurationDialogVisible = false
							})
			},
			editHandle(row) {
				//去除“用户名”的相关check
				this.$delete(this.editFormRules,'username');
				//将“用户名”输入框设置为非活性
				this.username_disabled = true;
				// this.$axios.get('/member/info?' + row.id).then(res => {
					// this.editForm = row
					this.editForm = Object.assign({}, row);//防止传递的是row的指针
					// this.editForm = res.data.data
					//获取操作者信息，提供max信息给el组件的输入框
					this.$axios.get('/sys/user/get/operator').then(res => {
						this.editForm.max_rd = res.data.data.rd + this.editForm.rd
						this.dialogVisible = true
						if(this.$refs['editForm'] != undefined)
							this.$refs['editForm'].clearValidate();//刷新validate状态
					})
					
					
				// })
			},
			//删除事件
			delHandle(id) {
				console.log("enter delete event")
				var ids = []

				if (id) {
					ids.push(id)
				} else {
					this.multipleSelection.forEach(row => {
						ids.push(row.id)
					})
				}
				console.log(ids)
				this.$axios.post("/member/delete", ids).then(res => {
					this.$message({
						showClose: true,
						message: '操作成功',
						type: 'success',
						onClose:() => {
							this.getUserList()
						}
					});
				})
			},
			
			repassHandle(id) {
				//给与参数
				this.resetMemberPassForm.id = id

				//显示对话框
				this.resetMemberPassVisible = true
			}
		}
	}
</script>

<style scoped>

	.el-pagination {
		float: right;
		margin-top: 22px;
	}
	.el-select-dropdown__item.selected {
		color: #606266;
		font-weight: 100;
	}

</style>